import type { Config } from "tailwindcss";
import defaultTheme from "tailwindcss/defaultTheme.js";

export const tailwindBaseTheme: Config["theme"] = {
  fontFamily: {
    sans: ["Montserrat", ...defaultTheme.fontFamily.sans],
    title: ["MartianGrotesk", ...defaultTheme.fontFamily.sans],
    mono: ["Courier New", ...defaultTheme.fontFamily.sans],
  },

  fontWeight: {
    normal: "400",
    semibold: "600",
    bold: "700",
    extrabold: "800",
  },

  colors: {
    primary: {
      DEFAULT: "#50228F",
      50: "#f5f4fe",
      100: "#efeafd",
      200: "#dfd8fc",
      300: "#c8b8fa",
      400: "#ac90f5",
      500: "#9263ef",
      600: "#8342e5",
      700: "#7430d1",
      800: "#6027b0",
      900: "#50228f",
      950: "#311461",
    },

    accent: {
      DEFAULT: "#ff4dc2",
      50: "#fef1fa",
      100: "#fee5f7",
      200: "#ffcaf2",
      300: "#ff9fe5",
      400: "#ff63d0",
      500: "#ff4dc2",
      600: "#f01299",
      700: "#d1057b",
      800: "#ad0765",
      900: "#8f0c56",
      950: "#580030",
    },

    success: {
      DEFAULT: "#00cf84",
      50: "#eafff4",
      100: "#cdfee3",
      200: "#a0face",
      300: "#63f2b5",
      400: "#25e297",
      500: "#00cf84",
      600: "#00a469",
      700: "#008358",
      800: "#006747",
      900: "#00553b",
      950: "#003023",
    },

    error: {
      DEFAULT: "#da211c",
      50: "#fef2f2",
      100: "#ffe2e1",
      200: "#ffc9c8",
      300: "#ffa4a2",
      400: "#fd706c",
      500: "#f5433e",
      600: "#da211c",
      700: "#be1b17",
      800: "#9d1b17",
      900: "#821d1a",
      950: "#470a08",
    },

    gray: {
      50: "#f7f7f7",
      100: "#e3e3e3",
      200: "#c8c8c8",
      300: "#a4a4a4",
      400: "#818181",
      500: "#666666",
      600: "#515151",
      700: "#434343",
      800: "#383838",
      900: "#313131",
      950: "#1a1a1a",
    },

    deco: {
      DEFAULT: "#33d0fd",
      50: "#f0faff",
      100: "#dff5ff",
      200: "#b8edff",
      300: "#7adfff",
      400: "#33d0fd",
      500: "#08bbef",
      600: "#0097cc",
      700: "#0078a5",
      800: "#046588",
      900: "#095471",
      950: "#06354b",
    },

    deco2: {
      DEFAULT: "#ffe24e",
      50: "#fefce8",
      100: "#fffac2",
      200: "#fff089",
      300: "#ffe24e",
      400: "#fdcd12",
      500: "#ecb306",
      600: "#cc8a02",
      700: "#a36105",
      800: "#864c0d",
      900: "#723f11",
      950: "#432005",
    },

    white: "#fff",
    black: "#000",
    rating: "#fdcd12",
    transparent: "transparent",
    current: "currentColor",
  },
  textColor: ({ theme }) => {
    return {
      ...theme("colors"),
      inherit: "inherit",
      subtle: theme("colors").gray["500"],
      disabled: theme("colors").gray["300"],
    };
  },
  borderColor: ({ theme }) => {
    return {
      DEFAULT: "#E5E5E5",
      "form-focus": theme("colors").gray["300"],
      ...theme("colors"),
    };
  },
  backgroundColor: ({ theme }) => {
    return {
      ...theme("colors"),
      disabled: theme("colors").gray["50"],
      current: "currentColor",
    };
  },

  borderRadius: {
    DEFAULT: "8px",
    sm: "4px",
    lg: "12px",
    xl: "16px",
    "2xl": "24px",
    full: "9999px",
    none: "0",
  },

  boxShadow: {
    lg: "0 0 16px rgba(139, 105, 196, 0.15)",
    "lg-bottom": "0 10px 16px rgba(139, 105, 196, 0.15)",
    bottom: "0 16px 7px rgba(139, 105, 196, 0.15)",
    relief: "inset 0 -3px 0 0 rgba(0, 0, 0, 0.15)",
    "relief-2": "inset 0 -2px 0 0 rgba(0, 0, 0, 0.15)",
    "relief-1": "inset 0 -1px 0 0 rgba(0, 0, 0, 0.15)",
    "inset-border": "inset 0 0 0 1px rgba(0, 0, 0, 0.15)",
    "inset-border-2": "inset 0 0 0 2px rgba(0, 0, 0, 0.15)",
    none: "none",
  },

  extend: {
    screens: {
      xs: "420px",
      "3xl": "1600px",
      tall: { raw: "(min-height: 1000px)" },
      "md-h": { raw: "(min-height: 668px)" },
      "-sm": {
        max: "639px",
      },
      "-xs": {
        max: "419px",
      },
    },
    width: {
      // @ts-expect-error non supported array
      screen: ["100vw", "100dvw"],
    },
    height: {
      // @ts-expect-error non supported array
      screen: ["100vh", "100dvh"],
    },

    spacing: {
      ...[13, 14, 15, 16, 17, 18, 22].reduce(
        (acc, current) => {
          acc[current] = `${4 * current}px`;

          return acc;
        },
        {} as Record<string, string>,
      ),
    },

    supports: {
      "timeline-scroll": "animation-timeline: scroll()",
      "not-timeline-scroll": "not(animation-timeline: scroll())",
    },

    fontSize: {
      "6xl": "3.5rem",
    },
  },
};
